<div
  class="sidebar-section"
  [ngClass]="{ 'sidebar-collapse': sharedService.collapseIcon }"
>
  <div class="logo-section">
    <acengage-logo></acengage-logo>
    <img class="collapseImg" src="shared-assets/images/acengageLogo.png" />
  </div>
  <div class="menus-section">
    <ng-container *ngFor="let menu of menus; let i = index">
      <div
        (click)="goTo(menu, false, i);selectMenu = menu.main_menu_id"
        [ngClass]="{ active: menu.is_active || checkActiveByRoute(menu) }"
        class="menu-item"
      >
        <div class="menu-name-icon">
          <img
            class="menu-icon"
            [pTooltip]="menu.name"
            tooltipPosition="right"
            [ngClass]="{ active: checkActiveByRoute(menu) }"
            [src]="menu.icon_relative_path"
            alt=""
          />
          <!-- <span class="icon queries-icon"></span> -->
          <div class="menuName">{{ menu.name }}</div>
        </div>
        <img
          *ngIf="menu.is_dropdownMenu && menu.sub_menu.length"
          [ngClass]="{
            rotate_to_down:
              menu.is_dropdownMenu &&
              subMenu &&
              menu.main_menu_id === selectMenu,
            rotate_to_top: menu.show_dropDown
          }"
          class="menu-arrow"
          src="shared-assets/images/sidebar_icons/arrow_right_light.svg"
          alt="arrow_icon"
        />
      </div>
      <div
        *ngIf="subMenu && menu.main_menu_id === selectMenu"
        [ngClass]="{ hasSubmenu: menu.sub_menu.length }"
        class="submenus"
      >
        <ng-container *ngFor="let submenu of menu.sub_menu; let sub = index">
          <div
            (click)="goTo(menu, submenu, i, sub)"
            [ngClass]="{ active: selectSubMenu === submenu.sub_menu_id }"
            class="menu-item"
          >
            <div class="menu-name-icon">
              <img
                class="marginRight10px menu-icon"
                [pTooltip]="submenu.name"
                [ngClass]="{
                  active: checkActiveByRoute(submenu),
                  marginRight10px: !sharedService.collapseIcon
                }"
                [src]="submenu.icon_relative_path"
                alt=""
              />
              <div class="menuName">{{ submenu.name }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
<img
  class="collapse-menu"
  [ngClass]="{ activeIcon: sharedService.collapseIcon }"
  (click)="collapseMenu()"
  src="shared-assets/images/sidebar_icons/arrow_right_light.svg"
  alt="arrow_icon"
/>
