import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommonAPIRequest, bulkDiscardPayload } from '../../models/common-api-request.model';
import { CEInterventionCustomField, CEInterventionReassignEmpPayload, CEInterventionUpdateFieldPayload } from '../candidate-engagement/models/CE-Interventions.interface';
import { CEInterventionStakeholdersDetails } from '../candidate-engagement/models/CE-Stakeholders.interface';
import { CEInterventionFollowUpCallPayload, CEInterventionInverviewQuestionUpdatePayload, CEInterventionUpdateCallStatusPayload } from '../candidate-engagement/models/CE-Intervention-Process.interface';
import { CEInterventionNCDetails } from '../candidate-engagement/models/CE-Intervention-NC.interface';

@Injectable({
  providedIn: 'root'
})
export class NHCampaignService {
  questionnaire: any = {};
  currentIntervention: number;
  constructor(private http: HttpClient) { }

  getInterventionTypes() {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/get_intervention_types`);
  }

  saveInterventionTypes(InputData: any) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/add-interventions-types`, InputData);
  }

  saveIntervention(InputData: any) {
    return this.http.post(`${environment.api_url}/campaign/new-hire-engagement/admin/add-interventions-types`, InputData);
  }
  createFlagReport(InputData: any) {
    return this.http.post(`${environment.api_url}/campaign/flag_report/create_report_request`, InputData);
  }

  InternalProcessDetails(id: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/get_all_interventions/` + id)
  }
  checkLocation(empID: any) {
    return this.http.get(`${environment.api_url}/campaign/flag_report/check_location/` + empID)
  }

  saveInternalProcessInterventionDetails(body: any) {
    return this.http.post(`${environment.api_url}/campaign/new-hire-engagement/admin/add-interventions`, body)
  }
  updateInternalProcessInterventionDetails(body: any) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/update-interventions`, body)
  }

  getInterventionDetails(id: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/get_all_interventions/` + id)
  }
  getInterventionDetailsSec(id: any) {
    return this.http.get(`${environment.api_url}/campaign/get_interventions_types/${id}`)
  }

  deleteInterventionTypes(id: any) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/delete-interventions-types/` + id, {});
  }

  basicDetails(id: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/get_campanign_details/` + id)
  }

  callDetails(id: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/get_all_interventions/` + id)
  }

  fieldMap(id: any) {
    return this.http.get(`${environment.api_url}/campaign/mapping_field/` + id)
  }

  // recordes(apiReqDtls: CommonAPIRequest, filterDetails: any, id: any) {
  //   return this.http.get(`${environment.api_url}/campaign/candidate-engagement/admin/get_record_status/${+id}?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`, filterDetails)
  // }
  uploadRecords(apiReqDtls: CommonAPIRequest, id: any, filterdata: any, color_code: any = '') {
    return this.http.post(`${environment.api_url}/campaign/get_candidates_info/${id}?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}&color_code=${color_code}`, filterdata)
  }

  // assignedUser(apiReqDtls: CommonAPIRequest, filterDetails: any, id: any) {
  //   return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/assigned_users_list/${+id}?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`)
  // }
  assignedUser(apiReqDtls: CommonAPIRequest, filterDetails: any, id: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/all_assigned_users_list/${+id}?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`)
  }

  getassignUserListing(apiReqDtls: CommonAPIRequest, filterDetails: any, id: any){
    return this.http.get(`${environment.api_url}/campaign/assigned_users_list/${+id}?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`)
  }

  listQuestion(id: any, interventionId: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/view_campaign_questions/${id}/${interventionId}`)
  }

  updateInterventionTypes(body: any) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/update-interventions-types`, body)
  }

  getCampaignDetails(id: any) {
    return this.http.get(`${environment.api_url}/campaign/get_details/` + id);
  }

  getQuestionStatus(id: any) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/get_question_status/` + id)
  }

  getIntervention(interventionID: number) {
    return this.http.get(`${environment.api_url}/campaign/get_interventions_types/${interventionID}`);
  }

  getInterventionQuestions(empID: number, interventionID: number) {
    return this.http.get(`${environment.api_url}/campaign/employee/questions?employee=${empID}&intervention=${interventionID}`);
  }

  getAssignedUsers(campaignId: number) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/admin/assigned_users_list/` + campaignId)
  }

  initiateHireInterview(interventionID: number, initiatePayload: { interview_mode: number, notes: string }) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/initiate_interview/${interventionID}`, initiatePayload);
  }

  getCECandidateInterventions(campaignID: number, empID: number) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/counsellor/get_interventions/${campaignID}/${empID}`);
  }

  getCECampaignBasicDtls(campaignID: number) { 
    return this.http.get(`${environment.api_url}/campaign/candidate-engagement/counsellor/get_campanign_details/${campaignID}`);
  }

  getCECandidateDetails(empID: number) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/counsellor/basic_details/${empID}`);
  }

  addFollowUpCall(followupPayload: CEInterventionFollowUpCallPayload) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/add-followup`, followupPayload);
  }

  setCEControllable(empID: number, ctrlStatus: number) {
    return this.http.put(`${environment.api_url}/campaign/candidate-engagement/counsellor/update_control_status/${empID}/${ctrlStatus}`, {});
  }

  reassignEmployeeCE(reassignEmpPayload: CEInterventionReassignEmpPayload) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/reassign`, reassignEmpPayload);
  }

  updtCustomField(empID: number, updtCustomFieldPayload: CEInterventionCustomField) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/add_custom_data/${empID}`, updtCustomFieldPayload);
  }

  getNonContactablesList(campaignID: number, empID: any, apiReqDtls: CommonAPIRequest) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/counsellor/get_non_contactable_callbacks/${campaignID}/${empID}?page=${apiReqDtls.page}&limit=${apiReqDtls.limit}&sort_order=${apiReqDtls.sort_order}`);
  }

  getEditableFields(campaignID: number) {
    return this.http.get(`${environment.api_url}/campaign/candidate-engagement/counsellor/get_editable_fields/${campaignID}`);
  }

  updtEditableFields(empID: number, updtFieldsPayload: CEInterventionUpdateFieldPayload) {
    return this.http.put(`${environment.api_url}/campaign/candidate-engagement/counsellor/edit_record/${empID}`, updtFieldsPayload);
  }

  getCEStakeHoldersList(campaignID: number, empID: number) {
    return this.http.get(`${environment.api_url}/campaign/candidate-engagement/counsellor/get_stakeholders/${campaignID}/${empID}`);
  }

  saveStakeholders(campaignID: number, empID: number, stakeholdersPayload: CEInterventionStakeholdersDetails) {
    return this.http.put(`${environment.api_url}/campaign/candidate-engagement/counsellor/add_stakeholders/${campaignID}/${empID}`, stakeholdersPayload);
  }

  getInterventionCallDetails(interventionID: number) {
    return this.http.get(`${environment.api_url}/campaign/new-hire-engagement/counsellor/get_interventions_details/${interventionID}`);
  }

  deleteIntervention(interventionID: number) {
    return this.http.delete(`${environment.api_url}/campaign/candidate-engagement/counsellor/delete_intervention/${interventionID}`);
  }

  getCETreeNodes(campaignID: number) {
    return this.http.get(`${environment.api_url}/campaign/candidate-engagement/counsellor/tree_nodes/${campaignID}`);
  }

  updtCallStatus(interventionID: number, updtCallStatusPayload: CEInterventionUpdateCallStatusPayload) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/update_call_status/${interventionID}`, updtCallStatusPayload);
  }

  updateDissatisfaction(interventionID: number, dissatisfactionPayload: any) {
    return this.http.patch(`${environment.api_url}/campaign/new-hire-engagement/counsellor/dissatisfaction_update/${interventionID}`, dissatisfactionPayload);
  }

  updtNCStatus(updtNCPayload: CEInterventionNCDetails, interventionID: number) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/update_non_contactable_call_back/${interventionID}`, updtNCPayload);
  }

  finishInterview(interventionID: number, finishInterviewPayload: any) {
    return this.http.post(`${environment.api_url}/campaign/new-hire-engagement/counsellor/finish_interview/${interventionID}`, finishInterviewPayload);
  }

  questionCEUpdate(interventionID: number, obj: CEInterventionInverviewQuestionUpdatePayload) {
    return this.http.post(`${environment.api_url}/campaign/new-hire-engagement/counsellor/save_interview_response/${interventionID}`, obj)
  }

  getCouncellors(serviceID: number) {
    return this.http.get(`${environment.api_url}/campaign/list_counselors?service=${serviceID}`)
  }

  quitInterventions(interventionDetails: any) {
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/quit-candidate/${interventionDetails.candidate}`, interventionDetails);
  }

  viewHistory(apiReqDtls: CommonAPIRequest, id: any) {
    return this.http.get(`${environment.api_url}/campaign/employee/history?employee=${+id}&limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`)
  }

  updateQualityStatus(interventionID: number, qualityData:any){
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/counsellor/quality_management/${interventionID}`,qualityData)
  }

  viewEditRecords(apiReqDtls: CommonAPIRequest, filterdata: any) {
    return this.http.post(`${environment.api_url}/campaign/export_table/nhe?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`, filterdata)
  }
  categoryQuestion(id: number){
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/category_question/${id}`,{}) 
  }
  disableCategoryQuestion(id: number){
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/disable_category_question/${id}`,{}) 
  }
  categoryMapping(id: number,type: number){
    return this.http.put(`${environment.api_url}/campaign/new-hire-engagement/admin/update_category_mapping/${id}/${type}`,{}) 
  }

  getBuddyInductionDetails(empid: any, apiReqDtls: any) {
    return this.http.get(`${environment.api_url}/campaign/buddy_intervention/get_details/${empid}?sort_order=${apiReqDtls.sort_order}&search=${apiReqDtls.search}`)
  }

  exportBuddyInductionTemplate(empid: any, payload: any) {
    return this.http.post(`${environment.api_url}/campaign/buddy_intervention/export/${empid}`, payload)
  }

  updatedBuddyInductionIndividualValue(id: any, obj: any) {
    return this.http.patch(`${environment.api_url}/campaign/buddy_intervention/update/` + id, obj);
  }

  uploadBuddyInduction(body: any, id: any) {
    return this.http.post(
      `${environment.api_url}/campaign/buddy_intervention/upload/${id}`,
      body
    );
  }

  deleteBuddyInductionIndividualValue(id: any) {
    return this.http.delete(`${environment.api_url}/campaign/buddy_intervention/delete/${id}`);
  }

  sentScheduleBuddyInduction(id: any) {
    return this.http.post(`${environment.api_url}/campaign/buddy_intervention/sent_schedules/${id}`, id);
  }

  updateDocketFields(empID: number, updtFieldsPayload: any) {
    return this.http.patch(`${environment.api_url}/campaign/new-hire-engagement/counsellor/update_docket/${empID}`, updtFieldsPayload);
  }

  getWelcomeEmailList(empid: any, apiReqDtls: any) {
    return this.http.get(`${environment.api_url}/campaign/buddy_intervention/employee_emails/${empid}`)
  }

  triggerEmployeeEmail(record_id: any, id: any) {
    return this.http.post(`${environment.api_url}/campaign/buddy_intervention/trigger_employee_email/${record_id}/${id}`, {});
  }
  
  refreshVerbatim(
    payload:any
  ) {
    return this.http.patch(
      `${environment.api_url}/tata_dialer/interview/refresh`,
      payload
    );
  }

  regenerateVerbatim(
    payload:any
  ) {
    return this.http.patch(
      `${environment.api_url}/tata_dialer/interview/regenerate`,
      payload
    );
  }

  markAsReqOrNotReq(interventionID: any) {
    return this.http.patch(
      `${environment.api_url}/campaign/new-hire-engagement/counsellor/manage_intervention/${interventionID}`,
      {}
    );
  }

  getMappingFieldsOrder(campaign_id: any) {
    return this.http.get(
      `${environment.api_url}/campaign/mapping_fields_order/${campaign_id}`
    );
  }

}

