import { PermissionList } from '@acengage/app/app/state/app.model';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'acengage-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
})
export class TableActionsComponent implements OnInit {
  constructor() {}
  items: MenuItem[] = [
    // { label: 'Edit', command: (event) => { this.outputClickEvt(event.item.label); } },
    // { label: 'Download', icon: 'pi pi-fw pi-download', command: () => { this.outputClickEvt('delete'); } }
  ];
  @Input() set actionItems(value: MenuItem[]) {
    value = value.map((eachVal: MenuItem) => {
      eachVal.command = (event) => {
        this.outputClickEvt(event.item.label);
      };
      return eachVal;
    });
    this.items = [...value];
    // this.items = value;
  }
  @Input() selectedTableRows: Array<any> = [];
  @Input() isCheckSelectedRow = true;
  @Output() actionItemClicked = new EventEmitter<any>();
  @Input() permissionRole: PermissionList = {
    feature_name: '',
    uid: '',
    role: '',
    add: false,
    edit: false,
    delete: false,
    view: false,
    export: false,
    nc_weekly_update: false,
  };
  @ViewChild('menu', { static: false }) menu: any;
  @Input() disableActionButton = false;
  @Input() multipleRowsSelected = false;
  
  ngOnInit(): void {
    // if(this.permissionRole.edit){
    //   this.items = [
    //     { label: 'Edit', command: (event) => { this.outputClickEvt(event.item.label); } },
    //   ];
    // } else {
    //   this.items = [];
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['selectedTableRows']?.currentValue !==
      changes['selectedTableRows']?.previousValue
    ) {
      this.items = this.items.map((eachItem: MenuItem) => {
        // if (eachItem.label === 'Reactivate') {
        //   eachItem.disabled =
        //     changes['selectedTableRows']?.currentValue?.length === 1 &&
        //       changes['selectedTableRows']?.currentValue[0]?.max_nc_count_achieved === true
        //       ? false
        //       : true;
        // }
        if (eachItem.label === 'Edit' || eachItem.label === 'Cancel Invoice') {
          eachItem.disabled =
            changes['selectedTableRows']?.currentValue?.length === 1
              ? false
              : true;
        }
        // if (eachItem.label === 'Move into Pool') {
        //   eachItem.disabled =
        //     (changes['selectedTableRows']?.currentValue?.length === 1 && (changes['selectedTableRows']?.currentValue[0]?.call_type !== 'International'))
        //       ? false
        //       : true;
        // }

        if (eachItem.label === 'Move into Pool') {
          eachItem.disabled = changes['selectedTableRows']?.currentValue?.some(
            (item: any) => item.remove_status == 2
        ) ? true : changes['selectedTableRows']?.currentValue?.some(
            (item: any) => [3, 5, 8, 9].includes(item.latest_status)
        ) ? true : false;
        }
        if (eachItem.label === 'Assign Employee') {
          eachItem.disabled = changes['selectedTableRows']?.currentValue?.some(
            (item: any) => item.remove_status == 2
        ) ? true : changes['selectedTableRows']?.currentValue?.some(
            (item: any) => [8, 9].includes(item.latest_status)
        ) ? true : false;
        }
        if (eachItem.label === 'Reactivate') {
          eachItem.disabled = changes['selectedTableRows']?.currentValue?.every(
            (item: any) => item.max_nc_count_achieved === true
          ) ? false : true;
        }
        if (eachItem.label === 'Cancel Invoice' && changes['selectedTableRows']?.currentValue?.length === 1) {
          eachItem.disabled =
            changes['selectedTableRows']?.currentValue[0]?.is_cancelled;
        }
        if (eachItem.label === 'Employee Rejoined/Rehired') {
          eachItem.disabled = changes['selectedTableRows']?.currentValue?.some(
              (item: any) => [8, 9].includes(item.latest_status)
          ) ? true : false;
        }
        if (eachItem.label === 'Sent Account Credentials' || eachItem.label === 'Trigger bulk mail') {
          eachItem.disabled = changes['selectedTableRows']?.currentValue?.some((row:any) => row.status === "Deactivated") ? true : false;
        }
        return eachItem;
      });
      if (changes['selectedTableRows']?.currentValue?.length == 0) {
        this.menu?.hide();
      }
    }
  }

  outputClickEvt(actionType: string) {
    const formOutputData = {
      actionType,
      selectedRows: this.selectedTableRows,
    };
    this.actionItemClicked.emit(formOutputData);
  }
}
