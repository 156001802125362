import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  AfterContentChecked,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'acengage-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  constructor(private element: ElementRef<HTMLInputElement>) { }
  @Input() placeholder = '';
  @Input() inputType = 'text';
  @Input() rightIcon = false;
  @Input() isAddon = false;
  @Input() RestrictNegative = false;
  @Input() id = '';
  value: any;
  @Input() name = '';
  @Input() disabled = false;
  @Input() restrictType = false;
  @Input() maxLength: number | string | null = 60;
  @Input() validateOnly = false;
  @Input() isShowFloatLabel = true;
  @Input() readOnly:boolean=false;
  @Input() set allowedKeys(value: string | RegExp | null) {
    if (value === 'alpha') {
      this._allowedKeys = /^[A-Za-z\s]*$/;
    } else if (value === 'alphanum') {
      this._allowedKeys = /^[a-zA-Z0-9 ]*$/;
    } else {
      this._allowedKeys = value;
    }
  }
  _allowedKeys: string | RegExp | null = null;
  @Input() classesToAdd = '';
  @Input() allowHTMLTags = false;
  private valueChanges = new Subject<string>();
  private touches = new Subject();

  @Output() keyupOutput = new EventEmitter();

  handleEmptySpace(event: any) {
    if (event.which === 32 && !this.value.length) {
      event.preventDefault();
    }
  }

  validateLength(event: any) {
    if (this.restrictType) {
      if (event.target.value.length === this.maxLength) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  keyPressed($event: any) {
    this.keyupOutput.emit($event);
  }
  onChange = () => { };
  onTouch = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.touches.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value !== null && value !== undefined 
      ? (this.inputType === 'text' ? String(value).trim() : value) 
      : '';

  }
  
  updateValue(value: any) {
    this.value = value ? value.trim() : '';
    this.valueChanges.next(this.value);
  }
  touch() {
    this.touches.next(null);
  }

  checkAlphanumeric(event: any) {
    if (this.inputType === 'number' && !this.RestrictNegative) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[0-9?]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    } else {
      return true;
    }
  }

  restrictpastecharacters(event: any) {
    if (this.inputType === 'number' && !this.RestrictNegative) {
      const pastedText = event.clipboardData.getData('text/plain');
      var inp = String.fromCharCode(event.keyCode);
      if ((pastedText.includes('e') || pastedText.includes('E')) ) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  preventHTMLTagSymbol(event: KeyboardEvent): void {
    if (!this.allowHTMLTags) {
      const key: string = event.key?.toLowerCase();
      if (key === '<' || key === '>') {
        event.preventDefault();
      }
    }
  }
}
