import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommonAPIRequest } from '../../models/common-api-request.model';
import { CEBasicInfoDetailsOnAdd } from '@acengage/app/app/services-screens/candidate-engagement/models/CE-Details.interface';
import {
  CEInterventionCustomField,
  CEInterventionReassignEmpPayload,
  CEInterventionUpdateFieldPayload,
} from '../candidate-engagement/models/CE-Interventions.interface';
import {
  CEInterventionEachStakeholderDtls,
  CEInterventionStakeholdersDetails,
} from '../candidate-engagement/models/CE-Stakeholders.interface';
import { CEInterventionNCDetails } from '../candidate-engagement/models/CE-Intervention-NC.interface';
import {
  CEInterventionFollowUpCallPayload,
  CEInterventionInverviewQuestionUpdatePayload,
  CEInterventionUpdateCallStatusPayload,
} from '../candidate-engagement/models/CE-Intervention-Process.interface';
import { CESlotSelectionPayload,ChangeSlotPayload } from '../candidate-engagement/models/CE-Intervention-Slot-Selection.interface';
import { Subject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
@Injectable({
  providedIn: 'root',
})
export class CECampaignService {
  scrollVisibility: Subject<number> = new Subject<number>();
  constructor(private http: HttpClient) {}

  allowOnlyAlphabets(e: any) {
    const k: any = e.keyCode || e.which;
    const ok =
      (k >= 65 && k <= 90) || // A-Z
      (k >= 96 && k <= 105) || // a-z
      (k >= 35 && k <= 40) || // arrows
      k == 9 || //tab
      k == 46 || //del
      k == 8 || // backspaces
      k == 32; // space

    const ctrl = e.ctrlKey ? e.ctrlKey : k === 17 ? true : false;
    if (k == 86 && ctrl) {
      return;
    } else if (k == 67 && ctrl) {
      return;
    } else if (k == 65 && ctrl) {
      return;
    } else if (k == 88 && ctrl) {
      return;
    } else if (k == 90 && ctrl) {
      return;
    }

    if (!ok || (e.ctrlKey && e.altKey)) {
      e.preventDefault();
    }
  }

  allowOnlyNumber(e: any) {
    const k: any = e.keyCode || e.which;
    const ok =
      (k >= 35 && k <= 40) || // arrows
      k == 9 || //tab
      k == 46 || //del
      k == 8 || // backspaces
      (k >= 96 && k <= 105) ||
      (!e.shiftKey && k >= 48 && k <= 57) || // only 0-9
      k == 107 || // +
      (e.shiftKey && k == 187);
    const ctrl = e.ctrlKey ? e.ctrlKey : k === 17 ? true : false;
    if (k == 86 && ctrl) {
      return;
    } else if (k == 67 && ctrl) {
      return;
    } else if (k == 65 && ctrl) {
      return;
    } else if (k == 88 && ctrl) {
      return;
    } else if (k == 90 && ctrl) {
      return;
    }
    if (!ok || (e.ctrlKey && e.altKey)) {
      e.preventDefault();
    }
  }

  allowNumberAndDecimal(e: any) {
    const k: any = e.keyCode || e.which;
    const inputValue: string = e.target.value;
    // Check if the key code corresponds to an alphabetic character (letters)
    const isAlphabeticCharacter = (k >= 65 && k <= 90) || (k >= 97 && k <= 122);

    if (isAlphabeticCharacter) {
      e.preventDefault(); // Block alphabetic characters
      return;
    }

    // Define a set of special characters to block by ASCII code
    const blockedSpecialCharacterCodes = [49];

    // Check if the key code corresponds to a blocked special character
    const isBlockedSpecialCharacter = blockedSpecialCharacterCodes.includes(k);

    // Rest of your existing code to allow numbers and decimals...
    const decimalPointExists = inputValue.includes('.');
    const decimalPointIndex = inputValue.indexOf('.');
    const hasTwoDecimalDigits =
      decimalPointIndex !== -1 &&
      inputValue.substring(decimalPointIndex + 1).length >= 2;

    const ok =
      (k >= 35 && k <= 40) || // arrows
      k == 9 || // tab
      k == 46 || // del
      k == 8 || // backspaces
      (k >= 96 && k <= 105) ||
      (!e.shiftKey && k >= 48 && k <= 57) || // only 0-9
      k == 107 ||
      (e.shiftKey && k == 187) || // + (plus)
      !decimalPointExists ||
      (decimalPointExists && !hasTwoDecimalDigits && k >= 48 && k <= 57);

    const ctrl = e.ctrlKey ? e.ctrlKey : k === 17 ? true : false;

    if (k == 86 && ctrl) {
      return;
    } else if (k == 67 && ctrl) {
      return;
    } else if (k == 65 && ctrl) {
      return;
    } else if (k == 88 && ctrl) {
      return;
    } else if (k == 90 && ctrl) {
      return;
    }

    if (
      !ok ||
      (e.ctrlKey && e.altKey) ||
      (hasTwoDecimalDigits && k >= 48 && k <= 57) ||
      (e.shiftKey && k >= 48 && k <= 57) ||
      (e.shiftKey && k == 191) ||
      (e.shiftKey && k == 186) ||
      (e.shiftKey && k == 222) ||
      (e.shiftKey && k >= 219 && k <= 221) ||
      k == 191 ||
      k == 222 ||
      (k >= 219 && k <= 221) ||
      (k >= 186 && k <= 189)
    ) {
      e.preventDefault();
    }
  }

  checkForAlreadyExistEmail(arrayToCheck: any) {
    let emailAlreadyExist: any = {
      duplicate: false,
      duplicateEmail: null,
    };
    for (let i = 0; i < arrayToCheck.length; i++) {
      let obj: any = arrayToCheck[i] != undefined ? arrayToCheck[i] : '';
      if (
        arrayToCheck.filter((obj1: any, index: number) => {
          let extractObjtocheck: any = obj != undefined ? obj1 : '';
          return i != index && obj[3] == extractObjtocheck[3];
        }).length > 0
      ) {
        emailAlreadyExist = {
          duplicate: true,
          duplicateEmail: arrayToCheck.filter((obj1: any, index: number) => {
            let extractObjtocheck: any = obj1 != undefined ? obj1 : '';
            return i != index && obj[3] == extractObjtocheck[3];
          })[0][3],
        };
        break;
      }
    }
    return emailAlreadyExist;
  }

  getCEBasicInfoDtls(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/get_campanign_details/${campaignID}`
    );
  }

  getConnectDetails(
    campaignID: number,
    selectedConnect: string,
    apiReqDtls: CommonAPIRequest
  ) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details/${selectedConnect}/${campaignID}?page=${apiReqDtls.page}&limit=${apiReqDtls.limit}&sort_order=${apiReqDtls.sort_order}`
    );
  }

  getCEInternalDeadline(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/get_internal_process_deadlines/${campaignID}`
    );
  }

  getVerifiedFieldMappingDtls(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/mapping_field/${campaignID}`
    );
  }

  getUploadedRecords(
    apiReqDtls: CommonAPIRequest,
    filterData: any,
    campaignID: number,
    color_code: any = false
  ) {
    return this.http.post(
      `${environment.api_url}/campaign/get_candidates_info/${campaignID}?page=${apiReqDtls.page}&limit=${apiReqDtls.limit}&sort_order=${apiReqDtls.sort_order}&search=${apiReqDtls.search}&color_code=${color_code}`,
      filterData
    );
  }

  getAssignedUsers(apiReqDtls: CommonAPIRequest, campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/assigned_users_list/${campaignID}?page=${apiReqDtls.page}&limit=${apiReqDtls.limit}&sort_order=${apiReqDtls.sort_order}&search=${apiReqDtls.search}`
    );
  }
  getAssignedUsersnew(campaignId: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/assigned_users/` +
        campaignId
    );
  }

  getAllInterventions(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/get_all_interventions/${campaignID}`
    );
  }

  getAddedQuestionsList(campaignID: number, interventionID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/view_campaign_questions/${campaignID}/${interventionID}`
    );
  }

  saveCEBasicDetails(body: CEBasicInfoDetailsOnAdd) {
    return this.http.post(
      `${environment.api_url}/campaign/basics_details`,
      body
    );
  }

  getCECandidateInterventions(campaignID: number, empID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_interventions/${campaignID}/${empID}`
    );
  }

  getCECampaignBasicDtls(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_campanign_details/${campaignID}`
    );
  }

  getCECandidateDetails(empID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_basic_details/${empID}`
    );
  }

  getMappingFieldsOrder(campaign_id: any) {
    return this.http.get(
      `${environment.api_url}/campaign/mapping_fields_order/${campaign_id}`
    );
  }

  getCEBasicDetails(empID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_candidate_basic_details/${empID}`
    );
  }
  getCEContinuityDetails(empID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/ngage_continuity/get_details/${empID}`
    );
  }
  downloadRecords(obj: any) {
    return this.http.post(
      `${environment.api_url}/export/export`, obj
    );
  }

  setCEControllable(empID: number, ctrlStatus: number) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/update_control_status/${empID}/${ctrlStatus}`,
      {}
    );
  }

  reassignEmployeeCE(reassignEmpPayload: CEInterventionReassignEmpPayload) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/reassign`,
      reassignEmpPayload
    );
  }

  updtCustomField(
    empID: number,
    updtCustomFieldPayload: CEInterventionCustomField
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/add_custom_data/${empID}`,
      updtCustomFieldPayload
    );
  }

  getNonContactablesList(
    campaignID: number,
    empID: number,
    apiReqDtls: CommonAPIRequest
  ) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_non_contactable_callbacks/${campaignID}/${empID}?page=${apiReqDtls.page}&limit=${apiReqDtls.limit}&sort_order=${apiReqDtls.sort_order}`
    );
  }

  getEditableFields(campaignID: number, empid: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_editable_fields/${campaignID}?candidate=${empid}`
    );
  }

  updtEditableFields(
    empID: number,
    updtFieldsPayload: CEInterventionUpdateFieldPayload
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/edit_record/${empID}`,
      updtFieldsPayload
    );
  }

  getCEStakeHoldersList(campaignID: number, empID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_stakeholders/${campaignID}/${empID}`
    );
  }

  saveStakeholders(
    campaignID: number,
    empID: number,
    stakeholdersPayload: CEInterventionStakeholdersDetails
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/add_stakeholders/${campaignID}/${empID}`,
      stakeholdersPayload
    );
  }

  getAllQuery(apiReqDtls: any, queryParams: any, employeeID?: number) {
    const param = {
      search: queryParams.search,
      status: queryParams.status,
    };
    return this.http.post(
      `${environment.api_url}/campaign/queries-list/queries-campaign/${apiReqDtls}?page=${queryParams.page}&limit=${queryParams.limit}&sort_order=${queryParams.sort_order}&employee=${employeeID}`,
      param
    );
  }

  getFilterQuery() {
    return this.http.get(
      `${environment.api_url}/campaign/queries-list/query_filter_heading`
    );
  }

  getCEInterventionBasicDtls(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_campanign_details/${campaignID}`
    );
  }

  // saveCEBasicDetails(body: CEBasicInfoDetailsOnAdd) {
  //     return this.http.post(`${environment.api_url}/campaign/basics_details`, body)
  // }
  addCEQuery(queryParams: FormData) {
    return this.http.post(
      `${environment.api_url}/campaign/queries/`,
      queryParams
    );
  }

  getCEDetailQuery(queryParams: any) {
    return this.http.get(
      `${environment.api_url}/campaign/queries/${queryParams}`
    );
  }

  getInterventionDetails(interventionID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_interventions_details/${interventionID}`
    );
  }

  deleteIntervention(interventionID: number) {
    return this.http.delete(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/delete_intervention/${interventionID}`
    );
  }

  getCETreeNodes(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/tree_nodes/${campaignID}`
    );
  }

  updtCallStatus(
    interventionID: number,
    updtCallStatusPayload: CEInterventionUpdateCallStatusPayload
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/update_call_status/${interventionID}`,
      updtCallStatusPayload
    );
  }

  updtNCStatus(updtNCPayload: CEInterventionNCDetails, interventionID: number) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/call-back/${interventionID}`,
      updtNCPayload
    );
  }

  addFollowUpCall(followupPayload: CEInterventionFollowUpCallPayload) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/add-followup`,
      followupPayload
    );
  }

  initiateCEInterview(
    interventionID: number,
    initiatePayload: { interview_mode: number; notes: string }
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/initiate_interview/${interventionID}`,
      initiatePayload
    );
  }
  refreshVerbatim(
    payload:any
  ) {
    return this.http.patch(
      `${environment.api_url}/tata_dialer/interview/refresh`,
      payload
    );
  }

  getInterventionQuestions(empID: number, interventionID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/employee/questions?employee=${empID}&intervention=${interventionID}`
    );
  }

  finishInterview(
    interventionID: number,
    finishInterviewPayload: { interview_note: string; notes: string }
  ) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/finish_interview/${interventionID}`,
      finishInterviewPayload
    );
  }

  getCEInterventionsType() {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/get_candidate_engagement_intervention_types`
    );
  }

  getCEConnectType() {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/get_candidate_engagement_interventions`
    );
  }

  getInterventionType(interventionID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/get_interventions_types/${interventionID}`
    );
  }

  saveInterventions(body: any) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/admin/add-interventions`,
      body
    );
  }

  saveConnectDetails(body: any) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details`,
      body
    );
  }

  questionCEUpdate(
    interventionID: number,
    obj: CEInterventionInverviewQuestionUpdatePayload
  ) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/save_interview_response/${interventionID}`,
      obj
    );
  }

  initiateSlotCollection(
    initiateSlotPayload: { note: string },
    interventionID: number
  ) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/connect/initiate_slot_collection/${interventionID}`,
      initiateSlotPayload
    );
  }

  markAsReqOrNotReq(interventionID: number) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/connect/manage_intervention/${interventionID}`,
      {}
    );
  }

  updtStakeholderInfo(
    interventionID: number,
    updtStakeholderPayload: CEInterventionEachStakeholderDtls
  ) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/reassign_stakeholder/${interventionID}`,
      updtStakeholderPayload
    );
  }

  getCandidateDtlForSlot(slotID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/get_candidate_details/${slotID}`
    );
  }
  getNonContactableCandidateDtls(slotID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/non-contactable/get_candidate_details/${slotID}`
    );
  }
  getFlagReportDetails(id: number | null,key:string | null) {
    return this.http.get(
      `${environment.api_url}/campaign/client_flag_report/get_details/${key}/${id}`
    );
  }
  notifyCandidate(slotID: number) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/notify_candidate_slot/${slotID}`,{}
    );
  }
  getCandidateManagerDtlForSlot(slotID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/get_candidate_slot_details/${slotID}`
    );
  }
  getManagerDtlForSlot(slotID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/get_manager_slot_details/${slotID}`
    );
  }
  getCandidateSlots(slotID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/get_candidate_slot/${slotID}`
    );
  }
  getManagerSlots(slotID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/get_manager_slot/${slotID}`
    );
  }

  updtSlotSelection(slotID: number, updtSlotPayload: CESlotSelectionPayload) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/update_slot/${slotID}`,
      updtSlotPayload
    );
  }
  updtSlotchangeByCandidate(slotID: number, updtSlotPayload: any) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/update_candidate_slot/${slotID}`,
      updtSlotPayload
    );
  }
  updtSlotchangeByManager(slotID: number, updtSlotPayload: any) {
    return this.http.post(
      `${environment.api_url}/campaign/candidate-engagement/update_manager_slot/${slotID}`,
      updtSlotPayload
    );
  }
  updtNonContactableSlot(slotID: number, updtSlotPayload: any) {
    return this.http.post(
      `${environment.api_url}/campaign/non-contactable/save_nc_slot/${slotID}`,
      updtSlotPayload
    );
  }
  updateFlagReport(id: number, key: string,data:any) {
    return this.http.post(
      `${environment.api_url}/campaign/client_flag_report/client_response/${key}/${id}`,
      data
    );
  }

  confirmConnectSlot(slotID: number, slotNo: number) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/connect/confirm_intervention_schedule/${slotID}/${slotNo}`,
      {}
    );
  }

  submitConnectComment(
    interventionID: number,
    connectCmntPayload: { connect_comments: string }
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/connect/update_connect_comments/${interventionID}`,
      connectCmntPayload
    );
  }

  getCeInterventionTypes(campaignId: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/get_internal_process_deadlines/${campaignId}`
    );
  }

  getCEinterventionConnectManagers(campaignId: any) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details/managers/${campaignId}?limit=25000`
    );
  }

  getCEinterventionConnectLeaders(campaignId: any) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details/leaders/${campaignId}?limit=25000`
    );
  }

  getCEinterventionConnectRecruiters(campaignId: any) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details/recruiters/${campaignId}?limit=25000`
    );
  }

  getCEinterventionConnectBuddy(campaignId: any) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details/buddy/${campaignId}?limit=25000`
    );
  }

  getCEinterventionConnectPeer(campaignId: any) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/connect_details/peer/${campaignId}?limit=25000`
    );
  }

  ceDeleteIntervention(interventionId: any, type: any) {
    return this.http.delete(
      `${environment.api_url}/campaign/candidate-engagement/admin/delete_campaign_intervention/${interventionId}`,
      { body: type }
    );
  }

  getCouncellors(campaignId: any) {
    return this.http.get(
      `${environment.api_url}/campaign/list_counselors?service=3`
    );
  }

  updateCEQuery(id: any, queryParams: FormData) {
    return this.http.patch(
      `${environment.api_url}/campaign/queries/update/${id}`,
      queryParams
    );
  }

  updateConnectDate(
    updtConnectPayload: { date: string; is_mail_trigger_required: boolean },
    interventionID: number
  ) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/update_connect_date/${interventionID}`,
      updtConnectPayload
    );
  }

  viewSlotHistory(interventionID: any) {
    return this.http.get(`${environment.api_url}/campaign/candidate-engagement/appointment_schedule_history/${interventionID}`)
  }
  getAllCampaignConnectNames(
    campaignID: number,
    empID: number,
    connectType: number
  ) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/get_connect_interventions/${campaignID}/${connectType}/${empID}`
    );
  }

  updtInterventionQuality(interventionID: number) {
    return this.http.put(
      `${environment.api_url}/campaign/candidate-engagement/counsellor/quality_management/${interventionID}`,
      {}
    );
  }

  viewEditRecords(apiReqDtls: CommonAPIRequest, filterdata: any) {
    return this.http.post(
      `${environment.api_url}/campaign/export_table/ce?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}&sort_order=${apiReqDtls.sort_order}`,
      filterdata
    );
  }

  removeAddedStakeholders(connectType: number, stakeholderID: string) {
    return this.http.delete(
      `${environment.api_url}/campaign/candidate-engagement/admin/delete_stakeholder/${connectType}/${stakeholderID}`
    );
  }

  getStakeholderToManage(campaignID: number) {
    return this.http.get(
      `${environment.api_url}/campaign/candidate-engagement/admin/manage_stakeholder_section/${campaignID}`
    );
  }

  regenerateVerbatim(
    payload:any
  ) {
    return this.http.patch(
      `${environment.api_url}/tata_dialer/interview/regenerate`,
      payload
    );
  }

}
