import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormGroup, Validators } from '@angular/forms';
import { PermissionPayload } from '../state/app.model';
import { ChangePassword } from '../setting/state/setting.modal';
import { CommonAPIRequest } from '../models/common-api-request.model';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  redirectToDetailPage$ = new Subject<any>();
  // powerBIData:any={}
  filter: any = {};

  constructor(private http: HttpClient, private store: Store) {}

  checkSetValidator(formGroup: FormGroup, ctrlName: string) {
    const ctrlValidator: any = formGroup.controls[ctrlName].errors;
    if (!ctrlValidator || !ctrlValidator?.required) {
      formGroup.controls[ctrlName].addValidators([Validators.required]);
      formGroup.get(ctrlName)?.updateValueAndValidity({
        emitEvent: false,
      });
      formGroup.controls[ctrlName].markAsDirty();
    }
  }

  checkClearValidator(formGroup: FormGroup, ctrlName: string) {
    const ctrlValidator: any = formGroup.controls[ctrlName].errors;
    if (ctrlValidator && ctrlValidator?.required) {
      formGroup.controls[ctrlName].clearValidators();
      formGroup.controls[ctrlName].updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  // downloadFile(fileUrl: string, fileName: string = 'file.jpg') {
  //   this.getBlobFromUrl(fileUrl).subscribe((fileBlobResp) => {
  //     const aTag = document.createElement('a');
  //     const objUrl = URL.createObjectURL(fileBlobResp);
  //     aTag.href = objUrl;
  //     aTag.download = fileName;
   //     aTag.click();
  //     URL.revokeObjectURL(objUrl);
  //   });
  // }
  showLoaderTrigger(isShow: boolean) {
    this.store.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
  downloadFile(
    fileUrl: string,
    fileName: string = 'file.jpg',
    extention?: string
  ) {
    if (extention) {
      this.showLoaderTrigger(true);
      this.getBlobFromUrl(fileUrl).subscribe((fileBlobResp) => {
        const aTag = document.createElement('a');
        const objUrl = URL.createObjectURL(fileBlobResp);

        aTag.href = objUrl;
        aTag.download = fileName;
        aTag.setAttribute('download', `${fileName}.${extention}`); // Include the file extension in the download attribute
        aTag.click();
        URL.revokeObjectURL(objUrl);
        this.showLoaderTrigger(false);
      });
    } else {
      this.showLoaderTrigger(true);
      this.getBlobFromUrl(fileUrl).subscribe((fileBlobResp) => {
        const aTag = document.createElement('a');
        const objUrl = URL.createObjectURL(fileBlobResp);
        aTag.href = objUrl;
        aTag.download = fileName;
         aTag.click();
        URL.revokeObjectURL(objUrl);
        this.showLoaderTrigger(false);
      });
    }
  }

  dateFormatter(date: Date | null) {
    if (date != null) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    } else {
      return '';
    }
  }

  formatTime(dateArg: Date | null) {
    if (dateArg != null) {
      const date = new Date(dateArg);
      const hours =
        date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      const minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      const time = hours + ':' + minutes;
      return time;
    } else {
      return '';
    }
  }

  setTime(timeInput: string) {
    const tmp = timeInput.split(':');
    const ms = new Date();
    ms.setHours(parseInt(tmp[0]));
    ms.setMinutes(parseInt(tmp[1]));
    return ms;
  }

  getCountry() {
    return this.http.get(`${environment.api_url}/country`);
  }

  getState(countryValue: number | string | null) {
    return this.http.get(`${environment.api_url}/state/${countryValue}`);
  }

  getCity(stateValue: number | string | null) {
    return this.http.get(`${environment.api_url}/city/${stateValue}`);
  }

  getNationality() {
    return this.http.get('shared-assets/json/nationality.json');
  }

  getDialCode() {
    return this.http.get('shared-assets/json/dial_code.json');
  }

  getLanguages() {
    return this.http.get('shared-assets/json/languages.json');
  }

  getBlobFromUrl(url: any) {
    return this.http.get(url, { responseType: 'blob' });
  }

  getSideMenu() {
    return this.http.get(`${environment.api_url}/authorization/api/feature`);
  }

  getSideMenuPermission(apiQuery: PermissionPayload) {
    return this.http.post(
      `${environment.api_url}/authorization/api/feature/permission`,
      apiQuery
    );
  }

  settingchangePassword(apiQuery: ChangePassword) {
    return this.http.post(
      `${environment.api_url}/user/api/reset-password/`,
      apiQuery
    );
  }

  // report section
  reportFilter() {
    return this.http.get(
      `${environment.api_url}/user/api/filter/report_heading`
    );
  }

  getreportData(apiReqDtls: CommonAPIRequest, filterDetails: any) {
    return this.http.post(
      `${environment.api_url}/user/my-report/get_report?limit=${apiReqDtls.limit}&page=${apiReqDtls.page}&search=${apiReqDtls.search}`,
      filterDetails
    );
  }
  // query email rectuir
  getEmailRecruitData(apiReqDtls: any) {
    return this.http.get(
      `${environment.api_url}/campaign/queries-list/default-recruiter/${apiReqDtls}`
    );
  }

  updateEveryapi(apiDtl: any, apiId: any) {
    return this.http.put(
      `${environment.api_url}/user/api/poll/${apiId}`,
      apiDtl
    );
  }
  getCEContinuityDetails(empID: string | number) {
    return this.http.get(
      `${environment.api_url}/campaign/ngage_continuity/get_details/${empID}`
    );
  }
  getCallRecordings(interventionId: string | number) {
    return this.http.get(
      `${environment.api_url}/tata_dialer/interview/get_recordings/${interventionId}`
    );
  }
  downloadRecords(obj: any) {
    return this.http.post(
      `${environment.api_url}/export/export`, obj
    );
  }
  saveFilterandColumns(Details: any) {
    return this.http.post(
      `${environment.api_url}/user_selected_columns/update_selected_columns/`,
      Details
    );
  }
  getSavedFilterandColumns(id: any) {
    return this.http.get(
      `${environment.api_url}/user_selected_columns/get_selected_columns/` + id
    );
  }
  getRealtimetesting(id: any) {
    return this.http.get(
      `${environment.api_url}/campaign/flag_report/notification_testing/` + id
    );
  }
  saveFilterandColumnsUpload(Details: any) {
    return this.http.post(
      `${environment.api_url}/user_selected_columns/upload_record_selected_columns/`,
      Details
    );
  }
  getSavedFilterandColumnsUpload(id: any,type:any) {
    return this.http.get(
      `${environment.api_url}/user_selected_columns/get_selected_columns/` + id+`?campaign_id=`+type
    );
  }
  getClients(){
    return this.http.get(
      `${environment.api_url}/powerbi/clients`
    );
  }
  getPowerBI(apiDtl:any) {  
    return this.http.put(
      `${environment.api_url}/powerbi/get_embed_params_for_single_report`,
      apiDtl
    );
  }
  powerBIRefresh(Details:any) {  
    return this.http.put(
      `${environment.api_url}/powerbi/refresh`,
      Details
    );
  }
  refreshHistory(data:any){
    return this.http.put(
      `${environment.api_url}/powerbi/refresh_history`,
      data
    );
  }
  questionFeedback(data:any,id?:any){
    return this.http.post(
      `${environment.api_url}/question/question_feedback/${id}`,
      data
    ); 
  }
  getDropdownQuestion(id:any){
    return this.http.get(
      `${environment.api_url}/question/get_dropdown_question/${id}`
    );
  } 
  getQRCode(){
    return this.http.get(
      `${environment.api_url}/user/get_qr_code`
    );
  } 
  updateFollowupAnswer(data:any,id:number){
    return this.http.patch(`${environment.api_url}/campaign/update_followup_answer/` + id, data);
  }
  getSavedFilterandColumnsClientWise(id: any,account_id:any) {
    return this.http.get(
      `${environment.api_url}/user_selected_columns/get_selected_columns/` + id+`?account_id=`+account_id
    );
  }

  updateCallRecord(obj: any) {
    return this.http.put(
      `${environment.api_url}/tata_dialer/interview/update_call_record`, obj
    );
  }

}
