import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as appActions from '@acengage/app/app/state/app.actions';
import { Store } from '@ngrx/store';
import { CommonService } from '@acengage/app/app/services/common.service';
import { CommonAPIRequest } from '@acengage/app/app/models/common-api-request.model';
import moment from 'moment';
import { TableHeader } from '@acengage/ui/models/table.model';
import {
  getLoginState,
  getPermissionSideMenuState,
} from '@acengage/app/app/authentication/state/auth.selectors';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'acengage-tata-dialer-recordings',
  templateUrl: './tata-dialer-recordings.component.html',
  styleUrls: ['./tata-dialer-recordings.component.scss'],
})
export class TataDialerRecordingsComponent implements OnInit {
  @Input() empID: number;
  @Input() campaignID: number; 
  @Input() interventionID: number;
  @Input() isBackBtn: boolean=true;
  @Output() goBack = new EventEmitter<null>();
  apiReqDtls: CommonAPIRequest = {
    page: 1,
    limit: 30,
    search: '',
    sort_order: '',
  };
  headers: TableHeader[] = [
    {
      headerName: 'User',
      objectKeyName: 'user',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Start Time',
      objectKeyName: 'start_time',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Attend Time',
      objectKeyName: 'answer_time',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'End Time',
      objectKeyName: 'end_time',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: ' Call Duration(Sec) (Including call connecting time)',
      objectKeyName: 'duration_in_sec',
      isSortable: false,
      isShow: true,
    },
    {
      headerName: 'Conversation Recorded',
      objectKeyName: 'recording_url',
      isSortable: false,
      isShow: true,
    },
  ];
  filterDetails: any;
  @Input() uploadedRecords: Array<any> = [];
  loading = false;
  totalCount = 0;
  viewHistoryList: any = [];

  iconDtls:any={ name: 'pi pi-arrow-left', position: 'left' };
  addIconDtls:any={ name: 'pi pi-plus', position: 'left' };
  @Output() rowSelectected = new EventEmitter();
  roles = '';
  roleId = '';
  isShowAudioDownload:boolean=false;
  constructor(
    private router: Router,
    private store: Store,
    private appStore: Store,
    private apiService: CommonService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (this.interventionID == null) {
      this.route.queryParams.subscribe((res: any) => {
        this.interventionID = res?.recordId
      });
    }

    this.store.select(getLoginState).subscribe((res: any) => {
      this.roles = res.roles[0]?.name;
      this.roleId = res.roles[0]?.id;
      this.setActionItems();
    });
    
  }
  
  setActionItems() {
    if (
      this.roles === 'Admin' ||
      this.roles === 'QA' ||
      this.roles === 'SME'
    ) {
      this.isShowAudioDownload = true;
    } else {
      this.isShowAudioDownload = false;
    }
    this.viewHistory();
  }
  audioDownload(url:any){
    const audioUrl = url; 
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
      let fileName: string = this.extractFilename(audioUrl ? audioUrl : '');
      this.apiService.getBlobFromUrl(audioUrl).subscribe((data) => {
        const file: File = new File([data], fileName, {
          type: data.type,
        });
        const aTag = document.createElement('a');
          const objUrl = URL.createObjectURL(file);

          aTag.href = objUrl;
          aTag.download = fileName;
          aTag.setAttribute('download', `${fileName}`); // Include the file extension in the download attribute
          aTag.click();
          URL.revokeObjectURL(objUrl);
          this.store.dispatch(appActions.showLoader({ showLoader: false }));
      })
  }
  extractFilename(url: string): string {
    const parts = url.split('/');
    const filenameWithQueryParams = parts[parts.length - 1];
    const filenameParts = filenameWithQueryParams.split('?');
    const filename = filenameParts[0];

    return filename;
  }
  viewHistory() {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    this.apiService
      .getCallRecordings(this.interventionID)
      .subscribe((res: any) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        if (res?.data) {
          this.viewHistoryList = [];
          this.viewHistoryList = res.data;
          this.viewHistoryList = res.data.map(
            ({ ...eachData }: any) => {
              eachData['start_time'] = eachData?.start_time
                ? this.formatData(eachData.start_time)
                : null;
              eachData['answer_time'] = eachData?.answer_time
                ? this.formatData(eachData.answer_time)
                : null;
              eachData['end_time'] = eachData?.end_time
                ? this.formatData(eachData.end_time)
                : null;
              eachData['isShowAudioDownload'] = this.isShowAudioDownload
                ? true
                : false;
              return eachData;
            }
          );
          this.totalCount = res.data.count;
        }
      });
  }

  formatData(value: Date) {
    return moment(value).format('DD/MM/YYYY hh:mm A');
  }
  toLocalDate(date: Date) {
    return moment.utc(date).local();
  }

  sortColumn(sortedValue: string) {
    this.apiReqDtls = { ...this.apiReqDtls, page: 1, sort_order: sortedValue };
    this.viewHistory();
  }

  filterHistory(filteredData: any) {
    this.apiReqDtls = {
      ...this.apiReqDtls,
      page: 1,
      search: filteredData.search,
    };
    this.viewHistory();
  }

  getNextpageRecords({ page, rows }: any) {
    this.apiReqDtls = { ...this.apiReqDtls, page: page || 1, limit: rows };
    this.viewHistory();
  }
  searchUploadedRecords(event: any) {}
  navigateToUploadedRecordsDetails($event: any) {
    this.rowSelectected.emit($event);
  }

  showConfirmationDialog = false
  addCallerRecordModal() {
    this.showConfirmationDialog = true
  }

  addCallerRecord(event: any) {
    this.store.dispatch(appActions.showLoader({ showLoader: true }));
    const fieldsObj = {
      "intervention_id": this.interventionID, 
      "call_id": event.formData.item
    }
    this.apiService.updateCallRecord(fieldsObj).subscribe(
      (res: any) => {
        this.showConfirmationDialog = false;
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.showToaster('success', '', res?.message);
        this.viewHistory();
      },
      (err) => {
        this.store.dispatch(appActions.showLoader({ showLoader: false }));
        this.store.dispatch(
          appActions.showToaster({
            class: 'error',
            header: 'Error',
            message: err.error.message,
            showToaster: true,
          })
        );
      }
    );
  }

  cancelModal() {
    this.showConfirmationDialog = false
  }

  showToaster(type: string, heading: string, message: string) {
    this.store.dispatch(
      appActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: true,
      })
    );
  }

}
