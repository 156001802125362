
<div class="btn-group">
    <div class="heading">
      <h2>Tata Dialer Recordings</h2>
    </div>
    <div class="btn-group">
      <acengage-button buttonText="Go Back" [classToadd]="'p-button-outlined p-button-secondary'" (buttonClicked)="goBack.emit()" [iconDtls]="iconDtls"  *ngIf="isBackBtn"></acengage-button>

      <acengage-button buttonText="Add Call Record" [classToadd]="'p-button-outlined p-button-secondary'" (buttonClicked)="addCallerRecordModal()" [iconDtls]="addIconDtls" *ngIf="this.roleId == '10'" >
    </acengage-button>
    </div>
  </div>
<div class="ce-history-table">
    <acengage-table [currentModule]="'CE'" [tableRecords]="viewHistoryList" [headerlabels]="headers"
    (pageChange)="getNextpageRecords($event)" [checkboxes]="false" [showActions]="false" [isShowEditColumns]="false"
    (filterTriggered)="filterHistory($event)" (sortTriggered)="sortColumn($event);" (audioDownload)="audioDownload($event)" [isShowFilter]="false" [isShowSearch]="false" [total]="totalCount" [tableLoader]="loading"
    >
   </acengage-table>
</div>

<!-- <acengage-dialog
  [openModel]="showConfirmationDialog"
  [headerText]="'Update Profile'"
  [confirmButtonText]="'Continue'"
  [cancelButtonText]="'Cancel'"
  (confirmButton)="addCallerRecord($event)"
  (cancelButton)="cancelModal()"
>
  
</acengage-dialog> -->

<acengage-user-input-popup *ngIf="showConfirmationDialog"
  [actionMessage]="'Please add the call_id that was taken from the Tata dialer.'"
  (closeClicked)="showConfirmationDialog=false" placeholder="Enter Call ID" [requiredPlaceholder]="'Call ID'"

  (confirmClicked)="addCallerRecord($event)"></acengage-user-input-popup>
