import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'acengage-tree-dropdown',
  templateUrl: './tree-dropdown.component.html',
  styleUrls: ['./tree-dropdown.component.scss'],
})
export class TreeDropdownComponent {
  dropDown = false;
  @Input() data: any = [];
  @Input() firstNodeType = 'nodes';
  @Input() placeholder: string | number;
  @Input() showSubmit = false;
  @Input() subMenuShowLeft = false;
  @Input() isSelectAnyLevel = true;
  @Input() disabled:boolean=false;
  @Input() treeValue: number | string | null | undefined;
  @Output() submitClicked = new EventEmitter<number | string>();
  @Output() isPotentialRisk = new EventEmitter<any>();
  @Output() treeId: EventEmitter<number | string> = new EventEmitter();
  @Output() treeFinalOptionId: EventEmitter<number | string> = new EventEmitter();
  @Output() controllable: EventEmitter<boolean | null> = new EventEmitter();
  @Output() is_exited: EventEmitter<boolean | null> = new EventEmitter();
  @Output() is_rfc: EventEmitter<boolean | null> = new EventEmitter();
  @Output() treeName: EventEmitter<string | number> = new EventEmitter();
  @Output() cancelClicked = new EventEmitter<null>();
  @Output() removeClicked = new EventEmitter<null>();
  originalTreeName: number | string;

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['treeValue'] &&
      changes['treeValue'].currentValue !== changes['treeValue'].previousValue
    ) {
      this.originalTreeName = this.treeValue ? this.treeValue : '';
    }
  }

  firstDropdown(i: number, data: any, lenght: number, fromSubmit = false) {
    if (!this.data[i].active) {
      for (let i = 0; i < this.data?.length; i++) {
        this.hideChildrenItems(this.data[i]);
      }
    } else {
      for (let i = 0; i < this.data?.length; i++) {
        this.data[i].active = false;
      }
    }
    this.data[i].active = true;
    this.treeValue = data.label;
    this.isPotentialRisk.emit(data);
    if (this.firstNodeType === 'trees' && data.is_drilldown) {
      this.treeId.emit(data?.first_node?.id || 0);
      this.treeName.emit(data?.first_node?.choice || '');
      this.controllable.emit(data?.first_node?.is_controllable);
    } else {
      this.treeId.emit(data.id);
      this.is_exited.emit(data.is_exited || data.is_rfc);
      this.is_rfc.emit(data.is_rfc);
      this.treeName.emit(data?.label || '');
      this.controllable.emit(data?.is_controllable);
    }
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if (
      (fromSubmit ||
        !lenght ||
        (this.firstNodeType === 'trees' && !data.is_drilldown)) &&
      this.showSubmit
    ) {
      if (this.firstNodeType === 'trees' && !data.is_drilldown) {
        this.submitClicked.emit(data?.first_node?.id || 0);
      } else {
        this.submitClicked.emit(data.id);
      }
      this.dropDown = false;
    }
  }
  secondDropdown(
    i: number,
    j: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (!this.data[i]?.children[j]?.active) {
      for (let j = 0; j < this.data[i]?.children?.length; j++) {
        this.hideChildrenItems(this.data[i].children[j]);
      }
    } else {
      for (let j = 0; j < this.data[i]?.children?.length; j++) {
        this.data[i].children[j].active = false;
      }
    }
    this.data[i].children[j].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }
  thirdDropdown(
    i: number,
    j: number,
    k: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (!this.data[i]?.children[j]?.children[k]?.active) {
      for (let k = 0; k < this.data[i]?.children[j]?.children?.length; k++) {
        this.hideChildrenItems(this.data[i].children[j].children[k]);
      }
    } else {
      for (let k = 0; k < this.data[i]?.children[j]?.children?.length; k++) {
        this.data[i].children[j].children[k].active = false;
      }
    }
    this.data[i].children[j].children[k].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }
  forthDropdown(
    i: number,
    j: number,
    k: number,
    l: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (!this.data[i]?.children[j]?.children[k]?.children[l]?.active) {
      for (
        let l = 0;
        l < this.data[i]?.children[j]?.children[k]?.children?.length;
        l++
      ) {
        this.hideChildrenItems(
          this.data[i].children[j].children[k].children[l]
        );
      }
    } else {
      for (
        let l = 0;
        l < this.data[i]?.children[j]?.children[k]?.children?.length;
        l++
      ) {
        this.data[i].children[j].children[k].children[l].active = false;
      }
    }

    this.data[i].children[j].children[k].children[l].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }
  fifthDropdown(
    i: number,
    j: number,
    k: number,
    l: number,
    m: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (
      !this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]?.active
    ) {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children?.length;
        tempIndex1++
      ) {
        this.hideChildrenItems(
          this.data[i].children[j].children[k].children[l].children[tempIndex1]
        );
      }
    } else {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children?.length;
        tempIndex1++
      ) {
        this.data[i].children[j].children[k].children[l].children[
          tempIndex1
        ].active = false;
      }
    }
    this.data[i].children[j].children[k].children[l].children[m].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }
  sixthDropdown(
    i: number,
    j: number,
    k: number,
    l: number,
    m: number,
    n: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (
      !this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
        ?.children[n]?.active
    ) {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
          ?.children?.length;
        tempIndex1++
      ) {
        this.hideChildrenItems(
          this.data[i].children[j].children[k].children[l].children[m].children[
            tempIndex1
          ]
        );
      }
    } else {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
          ?.children?.length;
        tempIndex1++
      ) {
        this.data[i].children[j].children[k].children[l].children[m].children[
          tempIndex1
        ].active = false;
      }
    }
    this.data[i].children[j].children[k].children[l].children[m].children[
      n
    ].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }

  seventhDropdown(
    i: number,
    j: number,
    k: number,
    l: number,
    m: number,
    n: number,
    o: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (
      !this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
        ?.children[n]?.children[o]?.active
    ) {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
          ?.children[n]?.children?.length;
        tempIndex1++
      ) {
        this.hideChildrenItems(
          this.data[i].children[j].children[k].children[l].children[m].children[
            n
          ].children[tempIndex1]
        );
      }
    } else {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
          ?.children[n]?.children?.length;
        tempIndex1++
      ) {
        this.data[i].children[j].children[k].children[l].children[m].children[
          n
        ].children[tempIndex1].active = false;
      }
    }
    this.data[i].children[j].children[k].children[l].children[m].children[
      n
    ].children[o].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }

  eightDropdown(
    i: number,
    j: number,
    k: number,
    l: number,
    m: number,
    n: number,
    o: number,
    p: number,
    data: any,
    lenght: number,
    fromSubmit = false
  ) {
    if (
      !this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
        ?.children[n]?.children[o]?.children[p]?.active
    ) {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
          ?.children[n]?.children[o]?.children?.length;
        tempIndex1++
      ) {
        this.hideChildrenItems(
          this.data[i].children[j].children[k].children[l].children[m].children[
            n
          ].children[o].children[tempIndex1]
        );
      }
    } else {
      for (
        let tempIndex1 = 0;
        tempIndex1 <
        this.data[i]?.children[j]?.children[k]?.children[l]?.children[m]
          ?.children[n]?.children[o]?.children?.length;
        tempIndex1++
      ) {
        this.data[i].children[j].children[k].children[l].children[m].children[
          n
        ].children[o].children[tempIndex1].active = false;
      }
    }
    this.data[i].children[j].children[k].children[l].children[m].children[
      n
    ].children[o].children[p].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.is_exited.emit(data.is_exited || data.is_rfc);
    this.is_rfc.emit(data.is_rfc);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);
    if (lenght === 0) {
      this.dropDown = false;
      this.treeFinalOptionId.emit(data.id);
    }
    if ((fromSubmit || !lenght) && this.showSubmit) {
      this.submitClicked.emit(data.id);
      this.dropDown = false;
    }
  }

  ninethDropdown(
    i: number,
    j: number,
    k: number,
    l: number,
    m: number,
    n: number,
    o: number,
    p: number,
    q: number,
    data: any,
    fromSubmit = false
  ) {
    this.data[i].children[j].children[k].children[l].children[m].children[
      n
    ].children[o].children[p].children[q].active = true;
    this.treeValue = data.label;
    this.treeId.emit(data.id);
    this.treeName.emit(data?.label || '');
    this.controllable.emit(data?.is_controllable);

    this.dropDown = false;
    if (this.showSubmit) {
      this.submitClicked.emit(data.id);
    }
  }

  hideChildrenItems(item: any) {
    item.active = false;
    if (item.children?.length) {
      item.children.forEach((child: any) => {
        this.hideChildrenItems(child);
      });
    }
  }

  clickoutSide() {
    if (this.dropDown) {
      this.dropDown = false;
      if (this.showSubmit || this.firstNodeType === 'trees') {
        this.treeValue = this.originalTreeName;
        this.treeId.emit(this.originalTreeName);
        this.treeName.emit(this.originalTreeName);
      }
    }
  }
  selectboxClick() {
    this.dropDown = !this.dropDown;
    // if (this.showSubmit || this.firstNodeType === 'trees') {
    //   this.treeValue = this.originalTreeName;
    //   this.treeId.emit(this.originalTreeName);
    //   this.treeName.emit(this.originalTreeName);
    // }
  }
  removeData() {
    this.dropDown = false;
    this.treeValue = '';
    this.treeId.emit('');
    this.treeName.emit('');
    this.cancelClicked.emit();
    this.controllable.emit(null);
    this.removeClicked.emit();
  }
}
