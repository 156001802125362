import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { QueryStatusOptions } from '../../models/data';
import { SelectOption } from '../../models/select.model';
import {
  QueryEmailRecurit,
  QueryFormField,
  QueryOtherFilesDetailsResponse,
} from '../../queries/models/queries-response.interface';
import { CECampaignService } from '../../services-screens/services/ce-campaign.service';
import { NHCampaignService } from '../../services-screens/services/new-hire-campaign.service';
import { CommonService } from '../../services/common.service';
import * as AppActions from '@acengage/app/app/state/app.actions';
import { CEInterventionTreeList,CEInterventionInterviewQuestions } from '../../services-screens/candidate-engagement/models/CE-Intervention-Process.interface';
import { Config } from '../../config';

@Component({
  selector: 'acengage-add-flag-report',
  templateUrl: './add-flag-report.component.html',
  styleUrls: ['./add-flag-report.component.scss'],
})
export class AddFlagReportComponent implements OnInit {
  @Input() addFlagReportForm: FormGroup;
  fetchReportType:any='';
  isLocationAvailable:any='';
  isCommentAvailable:any='';
  openQueryModel = false;
  iconDtls:any={ name: 'pi pi-arrow-left', position: 'left' };
  reportTypes:any=[
    {name:'Amber',value:1},
    {name:'Red',value:2}
  ]

  @Input() set openQuesModel(value: boolean) {
    this.openQueryModel = value;
  }
  @Input() isEdit = false;
  @Input() report_type:any = '';
  @Input() selectQueryId: number;
  @Input() interventionDetails: any;
  @Input() empID: number;
  @Input() campaignID: number; 
  @Input() interventionID: number;
  @Input() isBackBtn: boolean=true;
  @Output() goBack = new EventEmitter<null>();
  @Input() interventionInterviewQuestions =
    [] as CEInterventionInterviewQuestions[];
  treeList: CEInterventionTreeList[] = [];
  queryData: any;
  selectedTreeID: any;
  interventionNodeDtls: any;
  quillEditorInstance: any;
  path: any;
  isUpdateCallTreeTouched = false;
  isreportTypeTouched = false;
  queryStatus: Array<SelectOption> = QueryStatusOptions;
  uploadedOtherFilesForEdit: File[] = [];
  duplicates = [];
  confimationMessage:string='';
  showConfirmationDialog:boolean = false;
  @Output() updateQueryPopupState = new EventEmitter();
  isFromGlobalSearch = false;
  constructor(
    private fb: FormBuilder,
    private store: Store,
    private route: ActivatedRoute,
    private campaignNHEService: NHCampaignService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if(this.report_type){
      this.addFlagReportForm.patchValue({'reportType' : this.getValueByName(this.report_type)});
      // this.addFlagReportForm.get('reportType')?.updateValueAndValidity();
    }
    this.getQuestionDetails();
    this.isCountryAvailable();
  }
  onDescriptionChange(event: any) {
    this.addFlagReportForm.get('description')?.updateValueAndValidity();
  }
  onEditorInit(event: any) {
    const quillEditor = event.editor; // Access the Quill editor instance
    this.quillEditorInstance = event.editor;
    // Attach a native blur event listener
    quillEditor.root.addEventListener('blur', () => {
      this.addFlagReportForm.get('description')?.markAsTouched();
    });
  }
  getNameById(nodeId: number,nodes:any[]): string | undefined {
    const node = nodes.find(n => n.id === nodeId);
    return node ? node.name : undefined; // Return undefined if not found
  }
  getValueByName(reportTypeName: string): number | undefined {
    const report = this.reportTypes.find((report:any) => report.name === reportTypeName);
    return report ? report.value : ''; // Return undefined if not found
  }
  getQuestionDetails(){
    let questCheck = 'Risk Category';
    let result:boolean | number = false; // Default value when no match is found

    this.interventionInterviewQuestions.some((eachData: any) => {
      // Check if description matches the questCheck value
      if (eachData.description && eachData.description.toLowerCase().includes(questCheck.toLowerCase())) {
        console.log('Matching item:', eachData);
        if(eachData.comment){
          this.isCommentAvailable=eachData.comment;
        }
        let length = eachData.answers?.length;
        // Extract the option from the answers
        if(length){
          // const answerOption = eachData.answers?.[length - 1]?.option;
          const answerOption = Array.isArray(eachData.answers?.[length - 1]?.option)?eachData.answers?.[length - 1]?.option[0]?.option_id:eachData.answers?.[length - 1]?.option;
          const report_type=this.getNameById(answerOption,eachData.nodes);
          if(report_type === 'Red' || report_type === 'Amber' ){
            this.report_type=report_type;
          }
          else{
            this.report_type='';
          }
        }
        else{
          this.report_type='';
        }
      }
    });

    console.log(this.report_type);
    if(this.report_type){
      this.addFlagReportForm.patchValue({'reportType' : this.getValueByName(this.report_type)});
      // this.addFlagReportForm.get('reportType')?.updateValueAndValidity();
    }
    if(this.isCommentAvailable){
      this.addFlagReportForm.patchValue({'description' : this.isCommentAvailable});
      if (this.quillEditorInstance) {
        this.quillEditorInstance.root.innerHTML = this.isCommentAvailable
      }
      // this.addFlagReportForm.get('reportType')?.updateValueAndValidity();
    }
  }
  initForm() {
      this.createFlagReportForm();
  }
  selectedReport(type: string | number) {
    console.log('selectedReport',type)
    this.isreportTypeTouched = true;
    this.addFlagReportForm.controls['reportType'].setValue(type);
  }
  clearChoice() {
    this.queryData.node_name.choice = '';
    this.interventionDetails.call_status.choice = '';
  }

  checkAlreadyEmail(cntrl: any, i: any) {
    const dict: any = {};
    cntrl.controls.forEach((item: any, index: any) => {
      const email = item.value.toLowerCase();
      dict[email] = dict[email] || [];
      dict[email].push(index);
    });
    let duplicates: any = [];
    for (const key in dict) {
      if (dict[key].length > 1) duplicates = duplicates.concat(dict[key]);
    }
    this.duplicates = duplicates;
    if (this.duplicates.length) {
      cntrl.controls.forEach((item: any) => {
        item.setErrors(null);
      });
      for (const index of duplicates) {
        cntrl.at(+index).setErrors({ duplicated: true });
      }
    } else {
      cntrl.controls.forEach((item: any) => {
        item.setErrors(null);
      });
    }
  }
  getInterventionDtls(interventionID: number) {
    // this.showLoaderTrigger(true);
    this.campaignNHEService
      .getInterventionDetails(interventionID)
      .subscribe((interventionDtlResp: any) => {
        this.interventionDetails = interventionDtlResp?.data || {};
        this.addFlagReportForm
          .get('title')
          ?.setValue(this.interventionDetails?.call_status?.id);
        this.addFlagReportForm
          .get('description')
          ?.setValue(this.interventionDetails?.interview?.notes);
        this.selectedTreeID = this.interventionDetails?.call_status?.id;
        this.interventionNodeDtls =
          this.interventionDetails?.call_status?.choice;
        // this.showLoaderTrigger(false);
      });
  }
 


  createFlagReportForm() {
    this.addFlagReportForm = this.fb.group({
      reportType: this.fb.control('', [
        Validators.required,
      ]),
      location: this.fb.control('', [
        Validators.required,
      ]),
      reason: this.fb.control('', [
        Validators.required,
      ]),
      description: this.fb.control('', [
        Validators.required,
      ]),
      recommendation: this.fb.control('', [
        Validators.required,
      ]),
    });
  }
  
  isCountryAvailable() {
    this.showLoaderTrigger(true);
    this.campaignNHEService.checkLocation(this.empID).subscribe(
      (res: any) => {
        this.showLoaderTrigger(false);
        this.isLocationAvailable=res?.data;
        this.addFlagReportForm
          .get('location')
          ?.setValue(this.isLocationAvailable);
      },
      (err: any) => {
        console.log('err',err)
        this.showLoaderTrigger(false);
        this.showToaster('error', '', err?.error?.message);
      }
    );
  }

  flagReportSubmit() {
    // if (this.isEdit) {
    //   this.updateSubmitQuery();
    // } else {
      this.submitFlagReport(true);
    // }
  }

  submitFlagReport(verification_check:boolean) {
    console.log(this.addFlagReportForm.get('reportType'))
    if(this.addFlagReportForm.invalid){
      this.showToaster('error', '', 'Please fill all the fields.');
      return;
    }
    this.showLoaderTrigger(true);
    const params = this.addFlagReportForm.value;
    const empId = this.empID;
    const payload={
      employee_id:empId,
      reason:params.reason,
      location:params.location,
      description:params.description,
      recommendation:params.recommendation,
      report_type:params.reportType,
      intervention_id:this.interventionID,
      verification_check:verification_check,
    }
    this.campaignNHEService.createFlagReport(payload).subscribe(
      (res: any) => {
        this.isreportTypeTouched = false;
        this.showConfirmationDialog = false;
        this.confimationMessage = '';
        this.showLoaderTrigger(false);
        this.addFlagReportForm.reset();
        this.showToaster('success', '', 'Flag report created successfully');
        this.goBack.emit();
      },
      (err: any) => {
        console.log('err',err)
        this.showLoaderTrigger(false);
        this.isreportTypeTouched = false;
        if(err?.error?.code === 300 && err?.status === 400){
          this.showToaster('error', '', '',true);
          this.confimationMessage = err?.error?.message;
          this.showConfirmationDialog = true;
        }
        else{
          this.showToaster('error', '', err?.error?.message);
        }
      }
    );
  }
  cancelPop(){
    this.showConfirmationDialog = false;
    this.showToaster('error', '', '',true);
    // this.addFlagReportForm.reset();
    this.confimationMessage = '';
    this.isreportTypeTouched = false;
    // this.goBack.emit();
  }

  showToaster(type: string, heading: string, message: string,ishide?:boolean) {
    this.store.dispatch(
      AppActions.showToaster({
        class: type,
        header: heading,
        message: message,
        showToaster: !ishide,
      })
    );
  }
  showLoaderTrigger(isShow: boolean) {
    this.store.dispatch(AppActions.showLoader({ showLoader: isShow }));
  }
}
