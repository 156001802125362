import { createReducer, on, Action } from '@ngrx/store';
import * as fromActions from './app.actions';
import { AppState } from './app.state';
import { ToasterConfig } from '../models/toaster.model';
import {MenuPayload} from '../state/app.model'

export const APP_FEATURES = 'app';

export const initialState: AppState = {
  toasterConfig: {
    class: 'success',
    header: '',
    message: '',
    showToaster: false
  },
  showLoader: false,
  isRowClicked: false,
  selectedServiceType: 1,
  isSearchAvailable: false,
  menuItem: [],
  selectedMenu:0,
  selectedSubMenu:0
};


const reducer = createReducer(initialState,
  on(fromActions.showToaster, (state, props: ToasterConfig) => {
    return { ...state, toasterConfig: props };
  }),
  on(fromActions.showLoader, (state, { showLoader }) => ({ ...state, showLoader: showLoader })),
  on(fromActions.isRowClicked, (state, { isRowClicked }) => ({ ...state, isRowClicked: isRowClicked })),
  on(fromActions.selectedServiceType, (state, { selectedServiceType }) => ({ ...state, selectedServiceType: selectedServiceType })),
  on(fromActions.isSearchAvailable, (state, { isSearchAvailable }) => ({ ...state, isSearchAvailable: isSearchAvailable })),
  // side menu

  on(fromActions.sideMenuBar, (state) => ({ ...state, isLoading: true, })),
  on(fromActions.sideMenuBarSuccess,
    (state, result) => ({ ...state, menuItem: result.menuItem, selectedMenu: result.menuItem[1].main_menu_id, isLoading: false })),
  on(fromActions.sideMenuBarFailure, (state, { error }) => ({ ...state, error })),

  // click menu
  on(fromActions.selectedMenu, (state, { selectedMenu }) => ({ ...state, selectedMenu })),

  on(fromActions.selectedSubMenu, (state, { selectedSubMenu }) => ({ ...state, selectedSubMenu })),
)

export function AppReducer(state: AppState | undefined, action: Action) {
  return reducer(state, action);
}


